import { RootState } from "../store";

export const getCollectionYardDetailWasteTypes = (state: RootState) =>
  state.collectionYardDetailSettings.collectionYardDetail.collectionYardWasteTypes;

export const getCollectionYardDetailName = (state: RootState) =>
  state.collectionYardDetailSettings.collectionYardDetail.name;
export const getCollectionYardDetailAddress = (state: RootState) =>
  state.collectionYardDetailSettings.collectionYardDetail.address;
export const getCollectionYardDetailId = (state: RootState) =>
  state.collectionYardDetailSettings.collectionYardDetail.collectionYardId;

export const getCollectionYardDetailInitName = (state: RootState) => state.collectionYardDetailSettings.initName;
export const getCollectionYardDetailIsTouched = (state: RootState) => state.collectionYardDetailSettings.isTouched;
export const getCollectionYardDetailLogin = (state: RootState) =>
  state.collectionYardDetailSettings.collectionYardDetail.login;

export const getCollectionYardDetailCredentials = (state: RootState) => state.collectionYardDetailSettings.credentials;
