import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  COLLECTION_YARDS_SETTINGS_PAGE,
  GET_COLLECTION_YARD_DETAIL_URL,
  POST_COLLECTION_YARD_DETAIL_URL,
} from "../middleware/routes";
import {
  getCollectionYardDetailId,
  getCollectionYardDetailWasteTypes,
} from "../selectors/collectionYardDetailSettings";
import { RootState } from "../store";
import {
  ICollectionYardDetail,
  ICollectionYardDetailApi,
  ICollectionYardDetailCredentials,
  ICollectionYardDetailSettingsState,
} from "../types/collectionYardDetailSettings";
import { Status } from "../types/state";
import { axiosRequestApi } from "../utils/axiosRequest";
import { adaptPostForBacked, adaptResponseForFronted } from "./utils/collectionYardDetailSettingsAdapter";

export const allWasteTypesIds = [
  200101, 200102, 200103, 200104, 200108, 200110, 200111, 200121, 200123, 200125, 200126, 200133, 200134, 200135,
  200136, 200138, 200139, 200140, 200201, 200301, 200302, 200303, 200304, 200306, 200307, 200308, 200399, 20014001,
  20014002, 20014003, 20014004, 20014005, 20014006, 20014007,
];
export const nameMaxLength = 25;
export const addressMaxLength = 75;

const initCollectionYardDetail: ICollectionYardDetail = {
  collectionYardId: 0,
  name: "",
  address: "",
  collectionYardWasteTypes: allWasteTypesIds.map((id) => ({
    wasteTypeId: id,
    rate: 0,
    disabled: true,
  })),
};

const initCollectionYardDetailSettingsState: ICollectionYardDetailSettingsState = {
  requestId: "",
  status: Status.idle,
  errorMsg: null,
  isTouched: false,
  initName: "",
  collectionYardDetail: JSON.parse(JSON.stringify(initCollectionYardDetail)),
};

export const collectionYardDetailSettingsSlice = createSlice({
  name: "collectionYardDetailSettings",
  initialState: initCollectionYardDetailSettingsState,
  reducers: {
    collectionYardDetailSettingsUpdateName: (
      state: ICollectionYardDetailSettingsState,
      action: PayloadAction<string>
    ) => {
      state.isTouched = true;
      state.collectionYardDetail.name = action.payload;
    },
    collectionYardDetailSettingsUpdateAddress: (
      state: ICollectionYardDetailSettingsState,
      action: PayloadAction<string>
    ) => {
      state.isTouched = true;
      state.collectionYardDetail.address = action.payload;
    },
    collectionYardDetailSettingsUpdateWasteType: (
      state: ICollectionYardDetailSettingsState,
      action: PayloadAction<{ wasteTypeId: number; rate: number | string; disabled: boolean }>
    ) => {
      state.isTouched = true;

      const wasteType = state.collectionYardDetail.collectionYardWasteTypes.find(
        (wasteType) => wasteType.wasteTypeId === action.payload.wasteTypeId
      );

      if (wasteType) {
        wasteType.rate = action.payload.rate;
        wasteType.disabled = action.payload.disabled;
      }
    },
    resetCollectionYardDetailSettingsState: () => {
      return { ...initCollectionYardDetailSettingsState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCollectionYardDetailThunk.pending, (state, action) => {
        state.status = Status.requesting;
        state.requestId = action.meta.requestId;
      })
      .addCase(getCollectionYardDetailThunk.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) {
          return;
        }

        state.initName = action.payload.name;
        state.collectionYardDetail = action.payload;

        state.errorMsg = null;
        state.status = Status.success;
      })
      .addCase(getCollectionYardDetailThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
        window.location.replace(COLLECTION_YARDS_SETTINGS_PAGE);
      })

      .addCase(getCollectionYardDetailCredentialsThunk.fulfilled, (state, action) => {
        state.credentials = action.payload;

        state.errorMsg = null;
        state.status = Status.success;
      });
  },
});

export const getCollectionYardDetailThunk = createAsyncThunk<ICollectionYardDetail, number, { state: RootState }>(
  "collectionYardDetailSettings/getCollectionYardDetailThunk",
  async (id, { getState }) => {
    const state = getState();
    const response = await axiosRequestApi.get(`${GET_COLLECTION_YARD_DETAIL_URL}/${id}`);

    const collectionYardDetailApi = response.data as ICollectionYardDetailApi;

    return adaptResponseForFronted(collectionYardDetailApi, getCollectionYardDetailWasteTypes(state));
  }
);

export const updateCollectionYardDetailThunk = createAsyncThunk<any, void, { state: RootState }>(
  "collectionYardDetailSettings/updateCollectionYardDetailThunk",
  async (_, { getState }) => {
    const state = getState();
    const id = getCollectionYardDetailId(state);

    const body = adaptPostForBacked(state.collectionYardDetailSettings.collectionYardDetail);

    return await axiosRequestApi.post(`${POST_COLLECTION_YARD_DETAIL_URL}/${id}`, body);
  }
);

export const getCollectionYardDetailCredentialsThunk = createAsyncThunk<
  ICollectionYardDetailCredentials,
  void,
  { state: RootState }
>("collectionYardDetailSettings/getCollectionYardDetailCredentialsThunk", async (_, { getState }) => {
  const state = getState();
  const id = getCollectionYardDetailId(state);

  const response = await axiosRequestApi.get(`${GET_COLLECTION_YARD_DETAIL_URL}/${id}/credentials`);

  return response.data as ICollectionYardDetailCredentials;
});

export const {
  collectionYardDetailSettingsUpdateName,
  collectionYardDetailSettingsUpdateAddress,
  collectionYardDetailSettingsUpdateWasteType,
  resetCollectionYardDetailSettingsState,
} = collectionYardDetailSettingsSlice.actions;

export const collectionYardDetailSettingsReducer = collectionYardDetailSettingsSlice.reducer;
